import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import BaseContainer from "src/components/Base/BaseContainer"
import BoxList from "src/components/BoxList"
import { PrimaryTitle } from "src/components/Typography"

const subpages = [
  {
    title: "Resource library",
    subtitle: "Download our resources for free",
    path: "/resources",
    icon: "feather-book-open",
  },
  {
    title: "Animal Advocacy Training Center",
    subtitle: "Visit the platform with our online courses",
    path: "https://animal-advocacy-center.thinkific.com/collections",
    icon: "feather-globe",
  },
]

const ResourcesPage = () => {
  const [observed, setObserved] = useState(false)

  useEffect(() => {
    setObserved(true)
  }, [])

  return (
    <>
      <Seo title="Resources" />
      <PageHeader className={observed ? "-observed" : ""}>
        <PrimaryTitle as="h1">Resources</PrimaryTitle>
      </PageHeader>
      <BaseContainer>
        <BoxList items={subpages} />
      </BaseContainer>
    </>
  )
}

const PageHeader = styled.header`
  padding: 180px 0 50px;
  text-align: center;
  transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

  &:not(.-observed) {
    opacity: 0;
    transform: translateY(20px);
  }

  ${media.tablet} {
    padding: 250px 0 100px;
  }
`

export default ResourcesPage
